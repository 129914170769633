.table {
    font-size: 13px;
    font-weight: 600;
    border-collapse: separate;
    border-spacing: 0 7px;
    th {
        font-weight: 400;
        font-size: 13px;
        color: #717278;
        border-style: none;
    }
    tbody {
        tr {  
            align-items: center;
            td {
                vertical-align: middle;
                white-space: nowrap; 
                border-top: solid 1px #282938;
                border-bottom: solid 1px #282938;
                padding:  13px 5px;
                &:first-child {
                    border-left: solid 1px #282938;
                    border-top-left-radius: 7px;
                    border-bottom-left-radius: 7px;
                }

                &:last-child {
                    border-right: solid 1px #282938;
                    border-top-right-radius: 7px;
                    border-bottom-right-radius: 7px;
                }
                .currency-color{
                    color:#333A4B;
                }
                .badge{
                    margin-right: -6px;
                    vertical-align: text-top;
                    margin-top: -8px;
                    padding: 2px;
                    font-size: 8px;
                }
                @media(max-width:576px) {
                    &:first-child , &:nth-child(2){
                        white-space: normal;  
                    } 
                }
            }
            .up-trend {
                width: 0px;
                height: 0;
                border-top: 0px solid transparent;
                border-right: 8px solid #F55D6F;
                border-bottom: 8px solid transparent;
                margin-left: 5px;
                display: inline-block;  
            }
            .down-trend {
                width: 0px;
                height: 0;
                border-top: 8px solid transparent;
                border-right: 8px solid #21DBA6;
                border-bottom: 0px solid transparent;
                margin-left: 5px;
                display: inline-block;
            }
            @media(max-width:576px) {
                .td{
                    &:first-child{
                       white-space: normal;  
                    } 
                }
            }
        }
    }
}