.graph {
    font-size: 13px;
    .graph-background{    
        height: 500px;
    }
    .table {
        thead tr th {
            border: none;

            &:first-child {
                border: none
            }

            &:last-child {
                border: none;
            }
        }

        th {
            vertical-align: middle;
            white-space: nowrap;
            border-top: solid 1px #282938;
            border-bottom: solid 1px #282938;
            padding: 10px;

            &:first-child {
                border-left: solid 1px #282938;
                border-top-left-radius: 7px;
                border-bottom-left-radius: 7px;
            }

            &:last-child {
                border-right: solid 1px #282938;
                border-top-right-radius: 7px;
                border-bottom-right-radius: 7px;
            }
        }
    }

    .dln-btn {
        background-color: #7879F1;
        width: 100%;
        padding: 5px;
    }
    @media(max-width:576px) {
        font-size: 11px;
        .graph-background{
            height: 300px;
        }
    }
}