.card{
    width:176px;
    height:130px;
    border: solid 2px #282938;
    display: inline-block;
    margin-bottom: 5px;
    padding-top: 10px;
    align-items: center;
    &:first-child{
        margin-left: 0px;
    }
    &:last-child{
        margin-right: 0px;
    }
    .card-body{
        --bs-card-spacer-x:0px;
        --bs-card-spacer-y:0px
    }
    .card-title{
        white-space: nowrap; 
        font-size: 15px;
        margin-left: 2px;
        margin-right: 7px;
    }
    .card-subtitle{
        font-size: 19px;
        font-weight: 600;
        margin-left: 2px;
        margin-right: 7px;
        .currency-color{
            color:#333A4B;
        }   
    }
    .up-trend {
        width: 0px;
        height: 0;
        border-top: 0px solid transparent;
        border-right: 8px solid #F55D6F;
        border-bottom: 8px solid transparent;
        margin-left: 5px;
        display: inline-block;  
    }
    .down-trend {
        width: 0px;
        height: 0;
        border-top: 8px solid transparent;
        border-right: 8px solid #21DBA6;
        border-bottom: 0px solid transparent;
        margin-left: 5px;
        display: inline-block;
    }

    img{
        overflow: hidden;
        white-space: nowrap;
        font-size: 10px;
    }
}