.contact-us{
    line-height: 25px;
    .background{
        position: absolute;
        height: 100%;
        width: 100%;
        background-image: url(../../../public/imgs/pattern-cantactus.svg);
        z-index: -1;
        top: 75px;
        background-repeat: repeat-x;
    }
    .sentences{
        font-size: 12.5px;
    }
    .border-for-form{
        width: 70%;
        background-color: #181A25;
        border-radius: 9px;
        padding: 20px;
        margin: 30px 5px;
        font-size: 12px;
        .form-control{
            border: solid 1px #282938;
            &:focus{
                box-shadow: none;
            }
        }
    }
    .align-end{
        display: flex;
        align-items: end;
    }
    @media(max-width:768px){
        .border-for-form{
            width: 90%;
            margin: 30px auto;
        }
        .align-end{
            display: block;
            align-items: center;
            text-align: center;
        }
    }
}